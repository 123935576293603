import styled from 'styled-components';

export const ServicesContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(0.25turn, #4c1109, #a0410f, #4c1109); */
  background: #040014;
  /* background: #010606; */

  @media screen and (max-width: 768px) {
    height: 1400px;
    padding-top: 10px;
  }

  @media screen and (max-width: 480px) {
    height: 1550px;
    padding-top: 0rem;
  }
`;

export const ServicesWrapper = styled.div`
  /* max-width: 1000px; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  min-height: 65vh;
  padding: 0 50px;
  margin-top: -20px;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
    @media screen and (max-width: 1024px) {
  grid-gap: 0rem;
  padding: 0;
  min-height: 70vh;
    }

  @media screen and (max-width: 768px) {
    grid-template-columns:  1fr 1fr;
    padding: 0 20px;
  }
  @media screen and (min-width: 1440px) {
    
    min-height: 50vh;
      }
   @media screen and (max-width: 480px) {
    margin-top: 1rem;
    grid-gap: 1rem;
  }
`;

export const ServicesWrapper1 = styled.div`
  /* max-width: 1000px; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  min-height: 65vh;
  padding: 0 50px;
  margin-top: -20px;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
    @media screen and (max-width: 1024px) {
  grid-gap: 0rem;
  padding: 0;
  min-height: 70vh;
    }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 5.3rem;
    padding: 0 20px;
  }
  @media screen and (min-width: 1440px) {
    
    min-height: 50vh;
      }
   @media screen and (max-width: 480px) {
    margin-top: 1rem;
    grid-gap: 1rem;
    display: none;
  }
`;

export const ServicesCard = styled.div`
  /* background: #fff; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  width: 250px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
   @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesIcon = styled.img`
  height: 245px;
  width: 246px;
  margin-bottom: 10px;
  /* border-radius: 15px; */
`;

export const ServicesH1 = styled.h1`
  font-size: 90.5px;
  color: #efeaa1;
  font-family: 'Bangers', cursive;
  font-weight: bold;
  text-shadow: 6px 3px #795f04;
  margin-bottom: 0px !important;
  @media screen and (max-width: 480px) {
    font-size: 4rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 45.4px;
  font-family: 'Bangers', cursive;
  color: #efeaa1;
  font-weight: bold;
  margin-bottom: 0px !important;
  @media screen and (max-width: 768px) {
    font-size: 35.2px;
  }
`;

export const ServicesP = styled.p`
  font-size: 30.2px;
  font-family: 'Bangers', cursive;
  margin-top: -20px;
  text-align: center;
  color: #efeaa1;
`;
