/* eslint-disable */ 
import React from "react";
import ReactPlayer from "react-player";
import Eth from '../../images/Eth1.png';
import tweet from "../../images/tweet.png";
import Buzzard from "../../images/buzzard.png";
import Larry from "../../images/larry.png";
import MinerMike from "../../images/minermike.png"
import Bear from "../../images/bear.png";
import Sloth from "../../images/sloth.png";
import Jaguar from "../../images/jaguar.png";
import GringoGary from "../../images/GringoGary.png";
import Poacher from "../../images/poacher.png";

import {
  Bg,
  BGContainer,
  BtnContainer,
  ComingSoonContainer,
  ComingTextContainer,
  DiscordBtnLink,
  HeadingsContainer,
  HeroH1,
  HeroP,
  ImgContainer,
  MintBtnLink,
  NavBtn,
  NavBtnLink,
  NFTimg,
  OfferH1,
  SubtitleSpan,
  Symbolimg,
  Tweetimg,
  Videoclass,
} from "./HeroElements";
function HeroSection() {
  return (
    <>
      <Bg>
        <BGContainer>
          <ComingSoonContainer>
            <HeadingsContainer>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Symbolimg src={Eth} alt="Eth" />
                <HeroH1>Hero</HeroH1>
              </div>
              <div>
                <HeroH1>VS</HeroH1>
              </div>
              <div>
                <HeroH1>Villain</HeroH1>
              </div>
            </HeadingsContainer>
            <ImgContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={Buzzard} alt="buzzard" />
                <MintBtnLink
                  href="https://ethbuzzard.onejungle.org/"
                  target="_blank"
                >
                  Mint
                </MintBtnLink>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={Bear} alt="Bear" />
                <MintBtnLink
                  href="https://ethbear.onejungle.org/"
                  target="_blank"
                >
                  Mint!
                </MintBtnLink>
              </div>
              <Videoclass>
                <ReactPlayer
                  controls
                  // autoPlay muted

                  playing={true}
                  width="480"
                  height="480"
                  url="https://www.youtube.com/watch?v=0bWqC_z9iLs&loop=1"
                />
              </Videoclass>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={Larry} alt="Larry" />
                <MintBtnLink
                  href="https://larry.onejungle.org/"
                  target="_blank"
                >
                  Mint
                </MintBtnLink>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={MinerMike} alt="mike" />
                <MintBtnLink href="https://mike.onejungle.org/" target="_blank">
                  Mint
                </MintBtnLink>
              </div>
            </ImgContainer>
            <ImgContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={Jaguar} alt="Jaguar" />
                <MintBtnLink
                href="https://ethjaguar.onejungle.org/"
                target="_blank"
                >
                  Mint!
                </MintBtnLink>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={Sloth} alt="Sloth" />
                <MintBtnLink
                href="https://ethsloth.onejungle.org/"
                target="_blank"
                >
                  Mint!
                </MintBtnLink>
              </div>
              <Videoclass>
                <ReactPlayer
                  controls
                  // autoPlay muted

                  playing={true}
                  width="480"
                  height="480"
                  url="https://www.youtube.com/watch?v=VelXDJnV4LY"
                />
              </Videoclass>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={GringoGary} alt="GingoGary" />
                <MintBtnLink
                  href="https://gringo.onejungle.org/"
                  target="_blank"
                >
                  Mint!
                </MintBtnLink>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <NFTimg src={Poacher} alt="Poacher" />
                <MintBtnLink
                  href="https://poacher.onejungle.org/"
                  target="_blank"
                >
                  Mint!
                </MintBtnLink>
              </div>
            </ImgContainer>
          </ComingSoonContainer>
        </BGContainer>
        <BGContainer>
          <ComingTextContainer>
            <OfferH1>
              Bonus&nbsp;<SubtitleSpan>Free</SubtitleSpan> Polygon Hero!
            </OfferH1>
            <HeroP>Like/Follow/RT/Tag5</HeroP>
            <div style={{ display: "flex" }}>
              <Tweetimg src={tweet} alt="tweeter" />
              <a href="https://twitter.com/OnejungleOrg" target="_blank">
                <HeroP>@Onejungle.org</HeroP>
              </a>
            </div>
            <DiscordBtnLink
              href="https://discord.gg/c8FwpmV3JM"
              target="_blank"
            >
              Join Discord
            </DiscordBtnLink>
          </ComingTextContainer>
        </BGContainer>
        <BtnContainer>
          <NavBtn>
            <NavBtnLink href="https://buzzard.onejungle.org/" target="_blank">
              Buzzard
            </NavBtnLink>
          </NavBtn>
          <NavBtn>
            <NavBtnLink href="https://jaguar.onejungle.org/" target="_blank">
              Jaguar
            </NavBtnLink>
          </NavBtn>
          <NavBtn>
            <NavBtnLink href="https://sloth.onejungle.org/" target="_blank">
              Sloth
            </NavBtnLink>
          </NavBtn>
          <NavBtn>
            <NavBtnLink href="https://bear.onejungle.org/" target="_blank">
              Bear
            </NavBtnLink>
          </NavBtn>
        </BtnContainer>
      </Bg>
    </>
  );
}

export default HeroSection;
