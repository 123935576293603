import React from 'react';
import {
  ServicesContainer,
  Subtitle,
  Heading1,
  Welcome,
  Welcomeimg,
  SubtitleSpan,
  SubtitleSpan1,
  SubtitleSpan2,
  ImgAdjustment,
  // ServicesWrapper,
  //Cardstyle,
  // ServicesCard,
  // ServicesIcon,
  // ServicesH2,
  // ServicesP,
} from './ServicesElements';
import Faq1 from '../../images/roadmapcommunity.png';

// import Faq2 from "../../images/faq2.png";
// import Card from "./filp";
const Services = props => {
  return (
    <ServicesContainer id="story">
      <Welcome>
        <div style={{ flexDirection: "row", marginTop: "3rem"}}>
          <Heading1>5. CONTINUED QUARTERLY RELEASES</Heading1>
          <div style={{ flexDirection: "column" }}>
            <Subtitle>
              AFTER OUR GAME LAUNCH THERE WILL CONTINUE TO BE NEW
              <br />
              <div className="margin-letter-space"></div>
              <SubtitleSpan>
                HERO CHARACTERS ADDED EACH QUARTER SO AN ONGOING
                <br />
                <div className="margin-letter-space"></div>
                <SubtitleSpan1>
                  AND ENTERTAINING ATMOSPHERE MAINTAINS THIS LONG TERM
                  <br /> <div className="margin-letter-space"></div>
                  <SubtitleSpan2>
                    PLAN TO PRESERVE THE AMAZING AMAZON
                  </SubtitleSpan2>
                </SubtitleSpan1>
              </SubtitleSpan>
            </Subtitle>
          </div>
        </div>
        <ImgAdjustment>
          <Welcomeimg src={Faq1} />
        </ImgAdjustment>
      </Welcome>
    </ServicesContainer>
  );
};

export default Services;
