import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  Faqsdiv,
  // Faqstyle,
  // Faqimg,
  // ServicesWrapper,
  //Cardstyle,
  // ServicesCard,
  // ServicesIcon,
  // ServicesH2,
  // ServicesP,
} from "./ServicesElements";
// import Faq1 from '../../images/faq1.png';
// import Faq2 from '../../images/faq2.png';

// import Card from "./filp";
import Faq from "react-faq-component";

const data = {
  title: "",
  rows: [
    {
      title: "HOW MUCH IS A ONEJUNGLE NFT ?",
      content: `There are special offers in our discord channel https://discord.gg/c8FwpmV3JM
10 MATIC + gas`,
    },
  ],
};
const data1 = {
  title: "",
  rows: [
    {
      title: "WHERE CAN I VIEW MY NFTS?",
      content: `Once you have minted an NFT, you will be able to see it by connecting your crypto wallet to OpenSea.`,
    },
  ],
};
const data2 = {
  title: "",
  rows: [
    {
      title: "WHAT CAN I DO WITH MY ONEJUNGLE NFT?",
      content: `You can use your NFT as a profile picture online, and you can resell your NFTs for profit. Once the game launches, you will also be able to earn our native tokens in Play to Earn. We also will have a staking option for 5% daily rewards.`,
    },
  ],
};

const data4 = {
  title: "",
  rows: [
    {
      title: "ARE THERE ANY SECONDARY SALE ROYALTIES?",
      content: `Yes, 10% of the secondary sales will be deducted in royalties.`,
    },
  ],
};

const styles = {
  bgColor: "#0a2d0b",
  titleTextColor: "#efeaa1",
  rowTitleColor: "#efeaa1",
  // rowContentColor: 'grey',
  // arrowColor: "red",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};
const Services = (props) => {
  return (
    <ServicesContainer id="faq">
      <ServicesH1>frequently asked question's</ServicesH1>
      <ServicesH2>FAQ'S</ServicesH2>
      <Faqsdiv>
        <Faq data={data} styles={styles} config={config} />
        <div style={{ marginTop: 20 }}></div>
        <Faq data={data1} styles={styles} config={config} />
        <div style={{ marginTop: 20 }}></div>
        <Faq data={data2} styles={styles} config={config} />
        {/* <div style={{ marginTop: 20 }}></div>
        <Faq data={data3} styles={styles} config={config} /> */}
        <div style={{ marginTop: 20 }}></div>
        <Faq data={data4} styles={styles} config={config} />
        {/* <div style={{ marginTop: 20 }}></div>
        <Faq data={data5} styles={styles} config={config} /> */}
        {/* <Faqimg src={Faq2} alt="loading" /> */}
        {/* <div style={{ flexDirection: 'column' }}>
          {' '}
         
          <Card
            front='Secondary Royalties'
            back='0.5% lifetime for minters. 1.5% for Lion holders 4% Marketing and Team'
          />
          <Card
            front='Enter the Club Bar'
            back='Details on how to prove membership will be shared shortly after launch.'
          />
          
        </div> */}
        {/* <Faqimg src={Faq1} alt="loading" /> */}
      </Faqsdiv>
    </ServicesContainer>
  );
};

export default Services;
