import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
  LogoImage,
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  // NavBtn,
  // NavBtnLink,
  SoicalMedia,
  SoicalMediaIcon,
} from './NavbarElements';
import Discord from "../../images/Discode.png";
import OpenSea from "../../images/Opensea.png";
import Twitter from "../../images/Twitter.png";
// import Instagram from "../../images/Instagram.png";
import logo from "../../images/JungleLogo.png";
// import { FaInstagram, FaTwitter } from 'react-icons/fa';
// import { _connectButtonWork } from '../../libs/utils';
// import logo from '../../images/Rowdy Raccoon.png';
const Navbar = ({ toggle, props }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome} to="/">
              <LogoImage src={logo} alt="loading" />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="story"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Roadmap
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  to="faq"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  FAQ's
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  to="services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Team
                </NavLinks>
              </NavItem>
            </NavMenu>
            <SoicalMedia>
              {/* <NavBtn>
                <NavBtnLink href="https://discord.gg/Ymuj6Bym" target="_blank">
                  Join Discord
                </NavBtnLink>
              </NavBtn> */}
              <SoicalMediaIcon
                href="https://discord.gg/c8FwpmV3JM"
                target="_blank"
              >
                <img
                  src={Discord}
                  style={{ width: 40, height: 40, marginTop: -5 }}
                  alt={"Opensea"}
                />
              </SoicalMediaIcon>
              <SoicalMediaIcon
                href="https://opensea.io/collection/buzzard-bill "
                target="_blank"
              >
                <img
                  src={OpenSea}
                  style={{ width: 40, height: 40, marginTop: -5 }}
                  alt={"Opensea"}
                />
              </SoicalMediaIcon>
              <SoicalMediaIcon
                href="https://twitter.com/OnejungleOrg"
                target="_blank"
              >
                <img
                  src={Twitter}
                  style={{ width: 40, height: 40, marginTop: -5 }}
                  alt={"Opensea"}
                />
              </SoicalMediaIcon>
            </SoicalMedia>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
