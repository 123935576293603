import React from 'react';
import {
  ServicesContainer,
  Heading,
  Heading1,
  Subtitle,
  Subtitle1,
  Welcome,
  DirectionFlex,
  Welcomeimg,
  Subtitle1Span,
  SubtitleSpan,

} from './ServicesElements';
import Faq1 from '../../images/Welcome.png';


const Services = props => {
  return (
    <ServicesContainer id="about">
      <Welcome>
        <DirectionFlex>
          <Heading>
            WELCOME TO&nbsp;<Heading1>One Jungle.org</Heading1>
          </Heading>
          <div style={{ flexDirection: "column" }}>
            <Subtitle>
              JOIN US TO HELP SUPPORT AMAZON RAINFOREST PRESERVATION EFFORTS{" "}
              <br />
              <div className="margin-letter-space"></div>
              <SubtitleSpan>
                AMAZON WILDLIFE NFT COLLECTIONS RELEASED EVERY QUARTER
              </SubtitleSpan>
            </Subtitle>
            <Subtitle1>
              YOUR NFT WILL GIVE YOU EXCLUSIVE VOTING PRIVLEDGES <br />
              <div className="margin-letter-space"></div>
              <Subtitle1Span> Plus future game and staking.</Subtitle1Span>
            </Subtitle1>
          </div>
        </DirectionFlex>

        <Welcomeimg src={Faq1} alt="loading" />
      </Welcome>
    </ServicesContainer>
  );
};

export default Services;
