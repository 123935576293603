import React from 'react';
import {
  ServicesContainer,
  Heading,
  Subtitle,
  Heading1,
  Welcome,
  Welcomeimg,
  ImgAdjustment,
  SubtitleSpan,
  // ServicesWrapper,
  //Cardstyle,
  // ServicesCard,
  // ServicesIcon,
  // ServicesH2,
  // ServicesP,
} from './ServicesElements';
import Faq1 from '../../images/roadmapgiveaway.png';

// import Faq2 from "../../images/faq2.png";
// import Card from "./filp";
const Services = props => {
  return (
    <ServicesContainer id="story">
      <Welcome>
        <div style={{ flexDirection: "row" }}>
          <Heading>Roadmap</Heading>
          <Heading1>1. FOUR CHARACTER RELEASES</Heading1>
          <div style={{ flexDirection: "column" }}>
            <Subtitle>
              CHOOSE A HERO TO FIGHT FOR THE AMAZON RAINFOREST
              <br />
              <div className="margin-letter-space"></div>
              <SubtitleSpan>
                VOTE FOR YOUR FAVORITE ORGANIZATION TO SUPPORT
                <br />
                <div className="margin-letter-space"></div>
                <SubtitleSpan>
                  SHOW EVERYONE YOU CARE ABOUT OUR ENVIRONMENT!
                </SubtitleSpan>
              </SubtitleSpan>
            </Subtitle>
          </div>
        </div>
        <ImgAdjustment>
          <Welcomeimg src={Faq1} alt="loading" style={{ width: "80%", transform: "scaleX(-1)" }} />
        </ImgAdjustment>
      </Welcome>
    </ServicesContainer>
  );
};

export default Services;
