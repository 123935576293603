import React from "react";
import {
  ServicesContainer,
  Subtitle,
  Heading1,
  Welcome,
  Welcomeimg,
  SubtitleSpan,
  SubtitleSpan2,
  ImgAdjustment,
  // ServicesWrapper,
  //Cardstyle,
  // ServicesCard,
  // ServicesIcon,
  // ServicesH2,
  // ServicesP,
} from "./ServicesElements";
import Faq1 from "../../images/road22.png.png";

// import Faq2 from "../../images/faq2.png";
// import Card from "./filp";
const Services = (props) => {
  return (
    <ServicesContainer id="story">
      <Welcome>
        <ImgAdjustment>
          <Welcomeimg src={Faq1} alt="loading" />
        </ImgAdjustment>
        <div style={{ flexDirection: "row", marginTop: "3rem"}}>
          <Heading1>2. SOUTH AMERICAN CONDOR </Heading1>
          <div style={{ flexDirection: "column" }}>
            <Subtitle>
              OUR FIRST HERO BUZZARD BILL LAUNCH MARCH 28TH <br />
              <div className="margin-letter-space"></div>
              <SubtitleSpan>
                <SubtitleSpan2>
                  10,000 RANDOMLY GENERATED UNIQUE NFT ART PIECES
                </SubtitleSpan2>
                <br />
                <div className="margin-letter-space"></div>{" "}
                <SubtitleSpan>
                  YOU MIGHT EVEN GET ONE OF THE 10 RAREST!
                </SubtitleSpan>
              </SubtitleSpan>
            </Subtitle>
          </div>
        </div>
      </Welcome>
    </ServicesContainer>
  );
};

export default Services;
