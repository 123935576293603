import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  /* background: #0c0c0c; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 870px;
  position: relative;
`;
export const SubtitleSpan = styled.span`
  margin-bottom: 35px;
  font-size: 3vw;
  text-align: center;
  line-height: 24px;
  font-weight: 700;
  /* font-family: "Bangers", cursive; */
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: 17px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
    @media screen and (max-width: 480px) {
    font-size: 25px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const bannerHeader1 = styled.div`
  width: 100%;

  justify-content: center;
  align-items: center;
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
  }
`;
export const bannerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
export const Bg = styled.div`
  text-align: center;
  background-image: url("revision.jpg");
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  height: 1204px;
  background-position: center;
  padding-top: 4.2rem;
  
  @media screen and (max-width: 768px) {
    /* width:20%;
    height: 20%; */
    /* background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center; */
    /* background-attachment: fixed; */
    /* max-width: 10px; */
    /* display: inline-block; */
    /* width: 100%; */
    background-repeat: no-repeat;
    vertical-align: middle;
    background-size: cover;
    background-position: center;
    padding-top: 0rem;

  }

  @media screen and (max-width: 480px) {
    height: 2793px;
  }
`;
export const MarginDiv = styled.div`
  margin-top: 5%;
  @media screen and (max-width: 768px) {
    margin-top: 15%;
  }
`;
export const NFTimg = styled.img`
  /* margin-top: 5%; */
  width: 150px;
  height: 150px;
  @media screen and (max-width: 768px) {
    /* margin-top: 15%; */
  }
`;
export const Symbolimg = styled.img`
  /* margin-top: 5%; */
  width: 90px;
  height: 90px;
  /* margin-top: 1rem; */
  @media screen and (max-width: 480px) {
    /* margin-top: 15%; */
    width: 40px;
    height: 40px;
  }
`;
export const Tweetimg = styled.img`
  /* margin-top: 5%; */
  width: 30px;
  height: 30px;
  margin-top: 0.7rem;
  @media screen and (max-width: 768px) {
    /* margin-top: 15%; */
  }
`;
export const Videoclass = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -5rem;

  @media screen and (max-width: 768px) {
    /* margin-top: 15%; */
    /* display: none; */
    /* margin-top: 5rem; */
  }
  @media screen and (max-width: 480px) {
    margin-top: -2rem;
    padding: 2rem;

  }
`;
export const BtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 18rem;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    /* margin-top: 5px;
    width: 100%;
    height: auto; */
  }
`;
export const MintContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 33rem;
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const ComingSoonContainer = styled.div`
  display: flex;
  /* backdrop-filter: blur(10px); */
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  backdrop-filter: blur(10px);
  justify-content: space-between;
  flex-direction: column;
  width: 95%;
  align-items: center;
  /* margin-top: 33rem; */
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const ComingTextContainer = styled.div`
  display: flex;
  /* backdrop-filter: blur(10px); */
  /* box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5); */
  /* backdrop-filter: blur(10px); */
  justify-content: space-between;
  padding: 1rem;
  /* margin-top: 1rem; */
  flex-direction: column;
  width: 55%;
  background-color: #fff;
  align-items: center;
  /* margin-top: 33rem; */
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const ImgContainer = styled.div`
  display: flex;
  /* backdrop-filter: blur(10px); */
  justify-content: space-between;
  flex-direction: row;
  width: 95%;
  align-items: center;
  margin-top: -1rem;
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
   @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    margin-top: 0.5rem;
  }
`;
export const HeadingsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 95%;
  align-items: center;
  /* margin-top: 33rem; */
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
    @media screen and (max-width: 480px) {
    width: 100%;
    margin-top: 5rem;
  }
`;
export const BGContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: auto;
  align-items:center;
  /* margin-top: 33rem; */
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;
    /* visibility: hidden; */
    
  }
`;
export const SpaceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items:center;
  margin-top: 33rem;
  /* height: 15vh; */
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
// export const NavBtn = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* height: 12vh; */
//   @media screen and (max-width: 768px) {
//     /* height: 10vh; */

//   }
// `;
export const MintingSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  margin-top: -87rem;
  width: 100%;
`;
export const NavBtnLink = styled.a`
  /* width: 100%; */

  border-radius: 50px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 12px;
  color: white;
  font-size: 28px;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 100%;
  }
`;
export const MintBtnLink = styled.a`
  /* width: 100%; */

  border-radius: 50px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 8px 0;
  color: white;
  font-size: 18px;
  outline: none;
  margin: 0.5rem 0 1.5rem 0;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
    border: 1px solid black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 100%;
  }
`;
export const DiscordBtnLink = styled.a`
  /* width: 100%; */

  border-radius: 50px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 12px;
  color: white;
  font-size: 28px;
  margin: 0.8rem 0 0rem 0;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
    border: 2px solid black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 100%;
  }
`;
export const MintBtn = styled.a`
  width: 25%;
  border-radius: 50px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 22px;
  margin-top: 7px;
  color: white;
  font-size: 28px;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 52%;
    /* margin-left: -1px; */
  }
`;
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;

  @media screen and (max-width: 768px) {
    /* display: none; */
    margin: 1rem;
  }
`;
export const BtnCW = styled.a`
  width: 100%;
  border-radius: 50px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 22px;
  color: white;
  font-size: 28px;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 100%;
  }
`;
export const InputMint = styled.input`
  width: 100%;
  text-align: center;
  border-radius: 50px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  background: #000000;
  white-space: nowrap;
  /* padding: 10px 22px; */
  padding-right: auto;
  padding-left: 2vh;
  color: white;
  font-size: 38px;
  font-family: "AgencyBold";
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    padding-left: 0vh;
    width: 75%;
  }
`;
export const NavBtnLink1 = styled.button`
  width: 100%;
  border-radius: 20px;
  background: rgba(0, 0, 0, 1);
  white-space: nowrap;
  padding: 10px 22px;
  color: white;
  font-size: 28px;
  outline: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: black;
  }
  @media screen and (max-width: 768px) {
    font-size: 19px;
    width: 100%;
  }
`;

// export const NavBtn = styled.nav`
//   display: flex;
//   align-items: center;

//   @media screen and (max-width: 768px) {
//     display: none;
//   }
// `;

// export const NavBtnLink = styled.button`
//   border-radius: 50px;
//   background: white;
//   white-space: nowrap;
//   width: 70%;
//   height: 12vh;
//   padding: 10px 22px;
//   color: black;
//   font-size: 27px;
//   outline: none;
//   border: none;
//   cursor: pointer;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: #fff;
//     //color: #010606;
//   }
// `;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const VideoBg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url("bg.jpeg");
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: #000;
  font-size: 4vw;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 22px;
  }
`;

export const OfferH1 = styled.h1`
  color: #000;
  font-size: 3vw;
  /* font-weight: 600; */
  text-align: center;
  line-height: 0px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #000;
  font-size: 24px;
  text-align: center;
  max-width: 600px;
  line-height: 0px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
