import React from "react";
import Icon1 from "../../images/An.png";
import Icon2 from "../../images/Chris.png";
import Icon3 from "../../images/Adil.png";
import Icon4 from "../../images/Zohaib.png";
import larry from "../../images/larry.png";
import minermike from "../../images/minermike.png";
import GringoGary from "../../images/GringoGary.png";
import poacher from "../../images/poacher.png";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  ServicesWrapper1,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>THE TEAM</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Vilcatim</ServicesH2>
          <ServicesP>Admin</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Leonel</ServicesH2>
          <ServicesP>Quality Control</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon style={{ transform: "scaleX(-1)" }} src={Icon3} />
          <ServicesH2>Adil</ServicesH2>
          <ServicesP>Developer</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon style={{ transform: "scaleX(-1)" }} src={Icon4} />
          <ServicesH2>Zohaib</ServicesH2>
          <ServicesP>Developer</ServicesP>
        </ServicesCard>
      </ServicesWrapper>

    <ServicesWrapper1>
        <ServicesCard>
          <ServicesIcon src={larry} />
          <ServicesH2>EnTeRs</ServicesH2>
          <ServicesP>Artist</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={minermike} />
          <ServicesH2>Salma </ServicesH2>
          <ServicesP> Artist</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon style={{ transform: "scaleX(-1)" }} src={GringoGary} />
          <ServicesH2>Solo </ServicesH2>
          <ServicesP>Developer</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon style={{ transform: "scaleX(-1)" }} src={poacher} />
          <ServicesH2>Umz</ServicesH2>
          <ServicesP>Developer</ServicesP>
        </ServicesCard>
      </ServicesWrapper1>
    </ServicesContainer>
  );
};

export default Services;
